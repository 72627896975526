<template>
  <div class="slick-item-section" v-if="list && list.length">
    <div class="wrapper">
      <VueSlickCarousel v-bind="slickOptions" ref="carousel">
        <div class="carousel-item" v-for="(item, index) in list" :key="index">
          <ProductCard
            :item="item"
            :tag="tag"
            :addToCartChosenItem="addToCartChosenItem"
            @goToProduct="handleClick(item)"
            :currency="currency"
            @addToCart="addToCart"
          />
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import ProductCard from "../components/ProductCard.vue";

export default {
  name: "SlickItem",
  components: {
    ProductCard,
    VueSlickCarousel,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    tag: {
      type: String,
      default: "",
    },
    addToCartChosenItem: {
      type: String,
      default: "",
    },
    currency: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      slickOptions: {
        dots: false,
        infinite: false,
        arrows: true,
        slidesToShow: 6, // Initial number of visible slides
        slidesToScroll: 1, // Important for free mode
        variableWidth: true,
        swipe: true,
        swipeToSlide: true,
        responsive: [
          {
            breakpoint: 1601,
            settings: {
              slidesToShow: 5,
            },
          },
          {
            breakpoint: 1201,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 901,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 601,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 450,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
    };
  },
  methods: {
    handleClick(item) {
      this.$emit("goToProduct", item);
    },
    addToCart(item, id, item_type) {
      this.$emit("addToCart", item, id, item_type);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep(.slick-track) {
  display: flex;
}

.slick-item-section {
  .carousel-item {
    padding: 0 8px;
    width: 240px !important;
  }
}
</style>
